<template>
  <div>

     <b-row>
        <b-col md="12" sm="12">
          <h1 class="text-primary font-weight-bolder text-uppercase">liste de conformité</h1>
          <h3 class="mt-2 text-dark font-weight-bolder">Retrouvez la liste (non exhaustive) de tous les documents juridiques nécessaires à la gestion de votre cabinet</h3> 
        </b-col>
      </b-row>

    <b-row class="mt-3">
      <b-col md="12" sm="12">
        <b-card class="mb-2">
          <b-tabs content-class="pt-2">

            <b-tab :title="`Documents cabinet `" active>
              <b-card-text>
                <DocumentCabinet :docu-document="documentConformiteDemandeCabinet" @add-document="addDocument" @delete-docuement="deleteDocument"  @historise-docuement="historiserDocumentConformite" :rows-document="rowsConformiteCabinet">

                </DocumentCabinet>
              </b-card-text>
            </b-tab>

            <b-tab :title="`Documents interlocuteurs `">
              <b-card-text>
                <DocumentsCollaborateurs  :collaborateur-options="CollaborateurOptions" :rows-collaborateur-conformite="rowsCollaborateur" :docu-document="documentConformiteDemandeColaborateur" @add-document="addDocument"  @delete-docuement="deleteDocument"  @historise-docuement="historiserDocumentConformite" :rows-document="rowsConformiteColaborateur" >

                </DocumentsCollaborateurs>
            
              </b-card-text>
            </b-tab>

            <b-tab :title="`Procédures Internes `">
              <b-card-text>
                   <ProcedureInternes :docu-document="documentConformiteDemandeProcudureIntern" @add-document="addDocument"  @delete-docuement="deleteDocument"  @historise-docuement="historiserDocumentConformite" :rows-document="rowsProcudureIntern">

                  </ProcedureInternes>
            
              </b-card-text>
            </b-tab>

            <b-tab :title="`Archives `">
              <b-card-text>
                <Archives  :rows-document="rowsConformiteArichive" @fetch-colaborateur ="fetchCollaborateurAndQualificationByCourtier"  @fetch-document="fetch_documents_courtier_conformite"   @delete-docuement="deleteDocument" >

                </Archives>
            
              </b-card-text>
            </b-tab>
            
          </b-tabs>

          <ActionConfirmite @delete-docuement="deleteDocument"  @fetch-colaborateur ="fetchCollaborateurAndQualificationByCourtier"  @fetch-document="fetch_documents_courtier_conformite"  :data-confirmete="dataOfAction">
          </ActionConfirmite>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTab, BTabs, BCardText, BCard, BCol, BRow } from 'bootstrap-vue'
import DocumentCabinet from "@/views/conformite/detail_confirmite/DocumentCabinet.vue";
import Archives from "@/views/conformite/detail_confirmite/Archives.vue";
import ProcedureInternes from "@/views/conformite/detail_confirmite/ProcedureInternes.vue";
import DocumentsCollaborateurs from "@/views/conformite/detail_confirmite/DocumentsCollaborateurs.vue";
import ActionConfirmite from  "@/views/conformite/detail_confirmite/ActionConfirmite.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab, 
    BCard, 
    BCol,
    BRow,
    BTabs,
    BCardText,
    ToastificationContentVue,
    DocumentCabinet,
    Archives,
    ProcedureInternes,
    DocumentsCollaborateurs,
    ActionConfirmite
  },
  props: {
    showInBackOffice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      rowsConformite:[],
      rowsConformiteCabinet: [],
      rowsProcudureIntern: [],
      rowsConformiteColaborateur: [],
      rowsConformiteArichive: [],

      documentConformiteDemande: [],
      documentConformiteDemandeCabinet: [],
      documentConformiteDemandeProcudureIntern: [],
      documentConformiteDemandeColaborateur: [],
      dataOfAction: {},
      rowsCollaborateur: [],
      CollaborateurOptions: [],


    }
  }
  ,
  mounted() {
    this.fetchDocumentConformite()
    this.fetch_documents_courtier_conformite()
    this.fetchCollaborateurAndQualificationByCourtier()

  },
  methods: {
    addDocument(data) {
      this.dataOfAction = data

    },
    fetchDocumentConformite() {
      this.showLoading = true
      this.documentConformiteDemande = []
      this.$http
        .get('/document_conformite/fetchDocumentConformite')
        .then(res => {
          if (res.data.success) {
            this.documentConformiteDemande = res.data.data
            this.documentConformiteDemande.sort(function (a, b) {
              var nameA = a.typeLibelle.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
              var nameB = b.typeLibelle.toUpperCase();

              if (nameA < nameB) {
                return -1; // nameA comes before nameB
              }
              if (nameA > nameB) {
                return 1; // nameA comes after nameB
              }
              return 0; // names are equal
            });
            this.filterData()
            this.showLoading = false
          } else {
            this.showLoading = false
            this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
          }
        })
        .catch(err => {
          this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
          console.error(err)
        })
    },
    filterData() {
      this.rowsConformiteArichive = this.rowsConformite.filter(item => item.etat === 'Historique')
      this.rowsConformiteCabinet = this.rowsConformite.filter(item => item.id_document_nature === 1 && item.isEtat == "EN_COURS")
      this.rowsProcudureIntern = this.rowsConformite.filter(item => item.id_document_nature === 5 && item.isEtat == "EN_COURS")
      this.rowsConformiteColaborateur = this.rowsConformite.filter(item => item.id_document_nature === 2 )
      this.documentConformiteDemandeCabinet = this.documentConformiteDemande.filter(item => item.natureId === 1)
      this.documentConformiteDemandeProcudureIntern = this.documentConformiteDemande.filter(item => item.natureId === 5)
      this.documentConformiteDemandeColaborateur = this.documentConformiteDemande.filter(item => item.natureId === 2)

    },
    deleteDocument(id, component = null) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer le document ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(confirmed => {
        if (confirmed.value) {
          this.$http
            .delete(`/document/delete_document_courtier/${id}`)
            .then(res => {
              if (res.data.success) {
                // if (component != null) {
                //     this.fetchCollaborateurAndQualificationByCourtier()
                //   }

                this.fetch_documents_courtier_conformite()

                this.messageToast(res.data.message, 'Succès', 'success')
              } else {
                this.messageToast(res.data.message, 'Erreur', "danger")
              }
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', "danger")
            })
        }
      })
    },
    historiserDocumentConformite(documentCourtierId,component = null) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir archiver le document ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
        .then(confirmed => {
          if (confirmed.value) {
            this.$http
              .put(`/document/historiserDocumentConformite/${documentCourtierId}`)
              .then(res => {
                if (res.data.success) {
                  // if (component != null) {
                  //   this.fetchCollaborateurAndQualificationByCourtier()
                  // }
                  this.fetch_documents_courtier_conformite()
                  this.messageToast(res.data.message, 'Succès', 'success')
                } else {
                  this.messageToast(res.data.message, 'Erreur', "danger")
                }
              })
              .catch(error => {
                console.log(error)
                this.messageToast(error.response.data, 'Erreur', "danger")
              })
          }
        })
    },
    fetch_documents_courtier_conformite() {
      this.showLoading = true
      this.rowsConformite = []
      this.$http
        .get(`/document/fetch_documents_courtier_conformite/${this.currentUser.courtier_user[0].courtier_id}`)
        .then(res => {
          if (res.data.success) {
            this.rowsConformite = res.data.data
            this.filterData()
            this.showLoading = false
          } else {
            this.showLoading = false
            this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
          }
        })
        .catch(err => {
          this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
          console.error(err)
        })
    },
     fetchCollaborateurAndQualificationByCourtier() {
      this.CollaborateurOptions = [{
        value: null,
        text: '-- Choisissez --'
       }]
      this.rowsCollaborateur = []
      this.$http
      
        .get(`/collaborateur/fetchCollaborateurAndQualification/${this.currentUser.courtier_user[0].courtier_id}`)
        .then(res => {
          this.rowsCollaborateur = res.data
          res.data.forEach(el => {
            this.CollaborateurOptions.push({
              value: el.user.id,
              text: el.user.personne_physique.nom + ' ' + el.user.personne_physique.prenom,
            })
          })
        })
        .catch(err => {
          console.log(err);
        })
    },
    
   
  
  }
};
</script>

<style>
.vert-dote {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.red-dote {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.orange-dote {
  height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}
</style>
