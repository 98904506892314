<template>
    <div>

        <div>
         
            <b-card>
                <b-overlay :show="showLoading" no-wrap />

                <!-- table -->
                <vue-good-table :columns="columns" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                                }" @on-selected-rows-change="selectionChanged" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }">
                    

                    <template slot="table-row" slot-scope="props">

                       


                        <span class="d-flex justify-content-center" v-if="props.column.field === 'actions'">

                            <span class="cursor-pointer">
                                <feather-icon class="mr-50" icon="EyeIcon" @click="displayDocument(props.row.idDocument, props.row.Name, props.row.NomDuDocument)"/>
                            </span>

                            <span class="cursor-pointer" @click="$emit('delete-docuement', props.row.idDocument,'Action')">
                                <feather-icon   icon="Trash2Icon" size="20" class="mr-50" />
                             
                            </span>

                          

                        </span>

                       

                        <span v-else-if="props.column.field === 'TypeDeDocument'">

                        <!-- Manually set the value -->
                        <span class="d-flex justify-content-center">
                            <!-- Check if the type of document is 'Attestation de formation DDA' -->
                            <template v-if="props.row.id_document_type === 9">
                                {{ props.row.TypeDeDocument }} ({{ formatTime(props.row.nbHrsOfFormation) }})
                            </template>
                            <!-- If it's not 'Attestation de formation DDA', display an empty string -->
                            <template v-else>
                                {{ props.row.TypeDeDocument }}
                            </template>
                        </span>
                        </span>
                       

                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                    <b-row class="mt-2 align-items-center">
                        <b-col md="6" lg="5" xxl="3">
                            <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Affichage 1 à
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['3', '5', '10']"
                                class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })"
                            />
                            <span class="text-nowrap"> de {{ props.total }} entrées </span>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 align-items-center">
                    <b-col>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                        </b-pagination>
                    </b-col>
                </b-row>
              </template>
                    <div slot="emptystate" class="text-center align-middle">
                        <span>Aucune donn&eacute;e disponible dans le tableau</span>
                    </div>
                </vue-good-table>
                 <b-modal id="modal-display-document" ref="modal-display-document"
                            :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
                            cancel-variant="outline-secondary"
                            ok-title="Télécharger"
                            @ok="downloadDocument(document.base64, document.nameToDownload)"
                    >
                    <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden' }">
                        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
                        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
                    </form>
                </b-modal>
            </b-card>
        </div>


    </div>
</template>
  
<script>
import {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BPagination,
    BRow,
    VBToggle,
    VBTooltip,
    BModal,
    BImg,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ActionsDocument from "@/views/portefeuilles/reclamations/ActionDocument.vue"
import ReclamationOptions from "@/shared/constants/reclamation"
import ActionsReclamations from '@/views/portefeuilles/reclamations/ActionsReclamations'
//   import ParentFicheReclamation from './detail_fiche_reclamation/ParentFicheReclamation.vue'
import moment from 'moment'
export default {
    components: {
        flatPickr,
        ActionsReclamations,
        VueGoodTable,
        BPagination,
        BFormGroup,
        BFormInput,
        BBadge,
        BForm,
        ActionsDocument,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        BRow,
        BCol,
        BButton,
        BCard,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BFormSelectOption,
        BModal,
        BImg,
        ToastificationContent,
        moment
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            pageLength: 5,
            options: [
                {
                    value: null,
                    text: 'Choisissez'
                },
                {
                    value: 'Historiser',
                    text: 'Historiser'
                }
            ],
            columns: [
                {
                    label: 'Liste des documents',
                    field: 'TypeDeDocument',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                 {
                    label: 'Collaborateur concerné',
                    field: 'nomPrenom',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                {
                    label: 'Date d\'archivage',
                    field: 'dateFin',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                    sortFn(x, y) {
                        if (x == null && y == null) {
                            return 0;
                        } else if (x == null) {
                            return 1; // Mettre la date nulle à la fin
                        } else if (y == null) {
                            return -1; // Mettre la date nulle à la fin
                        }
                        x = moment(x, 'DD/MM/YYYY');
                        y = moment(y, 'DD/MM/YYYY');
                        if (x.isBefore(y)) {
                            return -1;
                        } else if (x.isAfter(y)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
            ],
            showLoading: false,
            rows: [],
            rowsConst: [],
            searchTerm: '',
            showLoading: false,
            document: {
                base64: null,
                name: null,
                nameToDownload: null,
                extensionDocument: null
            },

        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    props: {
        rowsDocument: {
            type: Array,
            default: [],
        },

    },
    watch: {
        rowsDocument: {
            deep: true,
            immediate: true,
            handler(val) {
                this.rowsConst = val
                this.rows = val
            }
        }

    },
    mounted() {

    },
    methods: {
        formatTime(time) {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        return hours + 'h ' + minutes + 'm';
        },
         downloadDocument(base64, name) {
            if (base64) {
                const a = document.createElement('a')
                a.href = base64
                a.download = name
                a.click()
                this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
            } else {
                this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
            }
        },
        clearDocumentDisplay() {
            this.document.base64 = null
            this.document.name = null
            this.document.nameToDownload = null
            this.document.extensionDocument = null
        },
        displayDocument(id, name, NomDuDocument) {
            this.showLoading = true
            this.$http
                .get(`/document/generate_base64_for_document/${id}`)
                .then(res => {
                    if (res.data.success) {
                        this.clearDocumentDisplay()
                        if (res.data.data.isDownload) {
                            this.downloadDocument(res.data.data.base64, name)
                        } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                            this.document.base64 = res.data.data.base64
                            this.document.name = NomDuDocument || name
                            this.document.nameToDownload = name
                            this.document.extensionDocument = res.data.data.extensionDocument
                            this.$refs['modal-display-document'].show()
                        } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                            this.document.base64 = res.data.data.base64
                            this.document.name = NomDuDocument || name
                            this.document.nameToDownload = name
                            this.document.extensionDocument = res.data.data.extensionDocument
                            this.$refs['modal-display-document'].show()
                            // const pdfWindow = window.open('')
                            // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
                            // this.downloadDocument(res.data.data.base64, name)
                        }
                        this.showLoading = false

                    } else {
                        this.showLoading = false
                        this.clearDocumentDisplay()
                        this.messageToast(res.data.message, 'Erreur', 'error')
                    }
                })
                .catch(err => {
                    this.showLoading = false
                    this.clearDocumentDisplay()
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                })
        },
        selectionChanged(params) {
            this.selectedrows_masse = params;

        },

    }
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style></style>
  