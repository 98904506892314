<template>
    <div>
      <!-- Title page -->
      
                <div style="margin-bottom: 20px;" class="custom-search">
                    <!-- advance search input -->
                    <b-row class="d-flex justify-content-end ">
                        <b-col  md="3">
                            <b-form-group label="Conformité" label-for="conformite">
                                <b-form-select id="conformite" v-model="selectedConformite" :options="ConformiteOptions" />
                            </b-form-group>
                        </b-col>
                        <b-col md="1" class="mt-1">
                            <b-button v-b-tooltip.hover.top="'Tout afficher'" v-b-tooltip.hover.v-primary v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                class="btn-icon mt-1" variant="primary" @click="clearAllFilter"> 
                            <feather-icon icon="RefreshCwIcon" />
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            
      
                <vue-good-table
                    class="custom-table-bottom"
                    :columns="collaborateurColumns"
                    :rows="rowsCollaborateur"
                    :rtl="direction"
                    style-class="vgt-table condensed"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: pageLengthCollaborateur,
                    rowsPerPageLabel: '',
                    dropdownAllowAll: false,
                    perPageDropdownEnabled: false,
                    nextLabel: 'Suivant',
                    prevLabel: 'Précédent',
                    ofLabel: 'de',
                    infoFn: params => ``
                    }"
                >
                    <template slot="table-row" slot-scope="props">
                    
                    <!-- Column: Action -->

                      
                        <span class="d-flex justify-content-center" v-if="props.column.field === 'collaborateur'">
                            {{ props.row.user.personne_physique.nom }}
                            {{ props.row.user.personne_physique.prenom }}
                        </span>

                        <span class="d-flex justify-content-center" v-if="props.column.field === 'date_collaborateur'">
                            {{ formatDate(props.row.user.personne_physique.created_at) }}
                            
                          <!--  {{ props.row.user.document }} -->
                        </span>
                    
                        <span v-if="props.column.field === 'conformite'">
                            <span class="cursor-pointer"  v-if="checkColaboratteur(rowsDocument, props.row.user.id)">
                                <feather-icon  icon="CheckIcon" size="20" :color="'green'" class="mr-50" />
                            </span> 
                            <span class="cursor-pointer" v-else>
                                <feather-icon  icon="XIcon" size="20" :color="'red'" class="mr-50" />
                            </span>
                            
                        </span>

                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                    <b-row class="mt-2 align-items-center">
                            <b-col md="6" lg="5" xxl="3">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                Affichage 1 à
                                
                                </span>
                                <b-form-select
                                v-model="pageLengthCollaborateur"
                                :options="['5','10','15']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                                />
                                <span class="text-nowrap"> de {{ props.total }} entrées </span>
                            </div>
                            </b-col>
                        </b-row>
                    <b-row class="mt-2 align-items-center">
                        <b-col>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLengthCollaborateur" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="25" />
                            </template>
                        </b-pagination>
                        </b-col>
                    </b-row>
                    </template>
                    <div slot="emptystate" class="text-center align-middle">
                    <span>Aucune donn&eacute;e disponible dans le tableau</span>
                    </div>
                </vue-good-table>

        <!-- advance search input -->
        <b-row class="d-flex justify-content-start">

            <b-col class="col-md-3 col-sm-6"><label for=""><h5  class="text-primary">Liste des documents par interlocuteur </h5></label></b-col>
   
        </b-row>

        <b-row class="d-flex justify-content-center">
            <b-col class="d-flex justify-content-start" >
                <b-form-group label="Choisissez votre interlocuteur" label-for="Choisissez-votre-interlocuteur">
                        <b-form-select id="Collaborateur" v-model="selectedCollaborateur" :options="CollaborateurOptions" />
                </b-form-group>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-form-group label="Progression" label-for="progression">
                    <b-form-select id="progression" v-model="selectedProgression" :options="ProgressionOptions" />
                </b-form-group>
            </b-col>
            <b-col style="margin-bottom: 20px;" md="1" class="mt-1">
                <b-button v-b-tooltip.hover.top="'Tout afficher'" v-b-tooltip.hover.v-primary v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-icon mt-1" variant="primary" @click="clearAllFilterCollaborateur">
                <feather-icon icon="RefreshCwIcon" />
                </b-button>
            </b-col>
        </b-row>
      
        <b-overlay :show="showLoading" no-wrap />

        <vue-good-table
            class="custom-table-bottom"
            :columns="documentsColumns"
            :rows="rows"
            :rtl="direction"
            style-class="vgt-table condensed"
            :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: pageLength,
            rowsPerPageLabel: '',
            dropdownAllowAll: false,
            perPageDropdownEnabled: false,
            nextLabel: 'Suivant',
            prevLabel: 'Précédent',
            ofLabel: 'de',
            infoFn: params => ``
            }"
        >
           

        <template slot="table-row" slot-scope="props">
                        <span class="d-flex justify-content-center" v-if="props.column.field === 'actions'">
                           
                            <span class="cursor-pointer" @click="$emit('add-document', props.row)" v-b-toggle.sidebar-document-conformite>
                                <feather-icon icon="DownloadIcon" size="20" class="mr-50" />
                            </span>

                            <span v-if="props.row.doc_telecharge" class="cursor-pointer" @click="$emit('delete-docuement', props.row.idDocument, 'docu')">
                                <feather-icon   icon="Trash2Icon" size="20" class="mr-50" />
                            </span>

                            <span v-else style="opacity: 0.5;">
                                <feather-icon   icon="Trash2Icon" size="20" class="mr-50" />
                            </span>

                            <span v-if="props.row.doc_telecharge"  class="cursor-pointer"  @click="$emit('historise-docuement', props.row.idD, 'docu')">
                                <feather-icon  icon="RefreshCcwIcon" size="20" class="mr-50" />
                            </span>

                            <span v-else style="opacity: 0.5;">
                                <feather-icon  icon="RefreshCcwIcon" size="20" class="mr-50" />
                            </span>

                        
                        </span>

                        <span class="d-flex justify-content-start" v-if="props.column.field === 'typeLibelle'">
                            {{ props.row.typeLibelle }} {{ props.row.time }}
                        </span>
                        
                        <span  v-if="props.column.field === 'date_debut'">
                            {{ props.row.date_debut }}
                        </span>

                        <span  v-if="props.column.field === 'date_fin'">
                            {{ props.row.date_fin }}
                        </span>

                       <span class="cursor-pointer d-flex justify-content-start"  v-if="props.column.field === 'doc_telecharge'"
                              @click="displayDocument(props.row.idDocument, props.row.doc_telecharge, props.row.NomDuDocument)">
                                <span v-if="props.row.doc_telecharge">
                                    <feather-icon class="mr-50" icon="EyeIcon"/>
                                 
                                 {{ props.row.doc_telecharge  }} {{ props.row.timeFormation }}



                                </span>
                        </span>

                        <span class="d-flex justify-content-center" v-if="props.column.field === 'progression'">

                            <span v-if="props.row.progression == 'vert'" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Conforme'"  :class="`${props.row.progression}-dote `"></span>
                            <span v-else-if="props.row.progression == 'red'" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Date dépassée'"  :class="`${props.row.progression}-dote `"></span>
                            <span v-else v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Document manquant'"  :class="`${props.row.progression}-dote `"></span>

                        </span>

                        <!-- <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span> -->
                       
                    </template>
    
      
            
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
            <b-row class="mt-2 align-items-center">
                    <b-col md="6" lg="5" xxl="3">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                        Affichage 1 à
                        
                        </span>
                        <b-form-select
                        v-model="pageLength"
                        :options="['5','10','15']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> de {{ props.total }} entrées </span>
                    </div>
                    </b-col>
                </b-row>
            <b-row class="mt-2 align-items-center">
                <b-col>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                    <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="25" />
                    </template>
                </b-pagination>
                </b-col>
            </b-row>
            </template>
            <div slot="emptystate" class="text-center align-middle">
            <span>Aucune donn&eacute;e disponible dans le tableau</span>
            </div>
        </vue-good-table>
           <b-modal id="modal-display-document" ref="modal-display-document"
                            :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
                            cancel-variant="outline-secondary"
                            ok-title="Télécharger"
                            @ok="downloadDocument(document.base64, document.nameToDownload)"
                    >
                    <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden' }">
                        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
                        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
                    </form>
                    </b-modal>


    </div>
  </template>
  
  <script>
  import {
      BPagination, BFormInvalidFeedback, VBTooltip, VBToggle, BFormInput, BRow, BModal, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardText, BCardHeader, BMedia,
    BImg, BOverlay, BFormSelect, BForm, BFormGroup
} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store/index'
  import { VueGoodTable } from 'vue-good-table'
  import flatPickr from 'vue-flatpickr-component'
  import { required } from '@validations'
  import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
  import moment from 'moment'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  

  
  
  export default {
    components: {
      VBToggle,
      BDropdown,
      BDropdownItem,
      BRow,
      BModal,
      BCol,
      BButton,
      BCard,
      BCardText,
      BCardHeader,
      BMedia,
      BImg,
      BOverlay,
      VueGoodTable,
      BPagination,
      BFormSelect,
      BForm,
      ToastificationContentVue,
      flatPickr,
      BFormGroup,
      BFormInput,
      ValidationProvider,
      ValidationObserver,
      BFormInvalidFeedback
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
        'b-tooltip': VBTooltip,
    },
    
    data() {
      return {
        currentUser: JSON.parse(localStorage.getItem('userData')),
        selectedProgression: null,
        selectedConformite : null,
        selectedCollaborateur : null,
        ConformiteOptions: [
                  {
                    value: 'null',
                    text: '-- Choisissez --',
                },
                {
                    value: 'Conforme',
                    text: 'Conforme'
                },
                {
                    value: 'Non conforme',
                    text: 'Non conforme'
                },
            ],
        ProgressionOptions : [
            {
                value: null,
                text: '-- Choisissez --',
            },
            {
                value : 'vert',
                text : 'Conforme',

            },
            {
                value : 'orange',
                text : 'Document manquant',
            },
            {
                value : 'red',
                text : 'Date dépassée',
            },
           
        ], 
        showLoading:false,
        name:null,
        selected_date_effet: null,
        pageLength: 10,
        pageLengthCollaborateur:10,
        dir: false,
        renderComponent: true,
        collaborateurColumns: [
          { label: 'Collaborateur', field: 'collaborateur', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
          { label: 'Fonction',  field: 'fonction', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
          { label: 'Date d\'ajout du collaborateur', field: 'date_collaborateur', type: 'date',dateInputFormat: 'dd/MM/yyyy',dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
          { label: 'Conformité', field: 'conformite', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' }
        ],
        rowsConst: [],
        document: {
            base64: null,
            name: null,
            nameToDownload: null,
            extensionDocument: null
        },
        rows: [],
        rowsCollaborateur:[],
        documentsColumns : [
                {
                    label: 'id',
                    field: 'typeId',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                    hidden: true

                },
                {
                    label: 'Liste des documents',
                    field: 'typeLibelle',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                {
                    label: 'Date de début',
                    field: 'date_debut',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                    sortFn(x, y) {
                        if (x == null && y == null) {
                            return 0;
                        } else if (x == null) {
                            return 1; // Mettre la date nulle à la fin
                        } else if (y == null) {
                            return -1; // Mettre la date nulle à la fin
                        }
                        x = moment(x, 'DD/MM/YYYY');
                        y = moment(y, 'DD/MM/YYYY');
                        if (x.isBefore(y)) {
                            return -1;
                        } else if (x.isAfter(y)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
                {
                    label: 'Date de fin',
                    field: 'date_fin',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                    sortFn(x, y) {
                        if (x == null && y == null) {
                            return 0;
                        } else if (x == null) {
                            return 1; // Mettre la date nulle à la fin
                        } else if (y == null) {
                            return -1; // Mettre la date nulle à la fin
                        }
                        x = moment(x, 'DD/MM/YYYY');
                        y = moment(y, 'DD/MM/YYYY');
                        if (x.isBefore(y)) {
                            return -1;
                        } else if (x.isAfter(y)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
                {
                    label: 'Document téléchargé',
                    field: 'doc_telecharge',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                {
                    label: 'Progression',
                    field: 'progression',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                } ],

        rowsDocuments: [
            { list_document: ' Attestation de capacité professionnelle ' },
            { list_document: ' Attestation de formation DDA ' },
            { list_document: ' Carte didentité ' },
            { list_document: '  Passeport  ' },
            { list_document: ' Curriculum vitae ' },
            { list_document: ' Déclaration dhonorabilité ' },
        ],
      }
    },

    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
        
     
    },
    props: {
        rowsDocument: {
            type: Array,
            default: [],
        },
        docuDocument: {
            type: Array,
            default: [],
        },
        CollaborateurOptions: {
            type: Array,
            default: [],
        }, 
        rowsCollaborateurConformite: {
            type: Array,
            default: [],
        },
    },
    watch: {
        selectedConformite() {
            this.filterCollaborateurs();
        },
        rowsDocument: {
            deep: true,
            immediate: true,
            handler(val) {
                if(this.selectedCollaborateur !=null){ this.remplierData(val, this.selectedCollaborateur) }
            }
        },
          rowsCollaborateurConformite: {
              deep: true,
              immediate: true,
              handler(val) {
                  this.rowsCollaborateur = val
              }
          },
        selectedProgression() {
            this.filterData()
        },
          selectedCollaborateur() {
            
            this.remplierData(this.rowsDocument,this.selectedCollaborateur)
        }
    },



    
    methods: {
        
        formatTime(time) {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        return hours + 'h ' + minutes + 'm';
        },
       
        formatDate(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        clearDocumentDisplay() {
              this.document.base64 = null
              this.document.name = null
              this.document.nameToDownload = null
              this.document.extensionDocument = null
        },
        displayDocument(id, name, NomDuDocument) {
              this.showLoading = true
              this.$http
                  .get(`/document/generate_base64_for_document/${id}`)
                  .then(res => {
                      if (res.data.success) {
                          this.clearDocumentDisplay()
                          if (res.data.data.isDownload) {
                              this.downloadDocument(res.data.data.base64, name)
                          } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                              this.document.base64 = res.data.data.base64
                              this.document.name = NomDuDocument || name
                              this.document.nameToDownload = name
                              this.document.extensionDocument = res.data.data.extensionDocument
                              this.$refs['modal-display-document'].show()
                          } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                              this.document.base64 = res.data.data.base64
                              this.document.name = NomDuDocument || name
                              this.document.nameToDownload = name
                              this.document.extensionDocument = res.data.data.extensionDocument
                              this.$refs['modal-display-document'].show()
                              // const pdfWindow = window.open('')
                              // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
                              // this.downloadDocument(res.data.data.base64, name)
                          }
                          this.showLoading = false

                      } else {
                          this.showLoading = false
                          this.clearDocumentDisplay()
                          this.messageToast(res.data.message, 'Erreur', 'error')
                      }
                  })
                  .catch(err => {
                      this.showLoading = false
                      this.clearDocumentDisplay()
                      this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                      console.error(err)
                  })
        },
        downloadDocument(base64, name) {
              if (base64) {
                  const a = document.createElement('a')
                  a.href = base64
                  a.download = name
                  a.click()
                  this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
              } else {
                  this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
              }
          },
        filterData() {
            this.rows = this.rowsConst
            if (this.selectedProgression ) {
                this.rows = this.rows.filter(item => item.progression === this.selectedProgression)
            }


        },
      filterCollaborateurs() {
            this.rowsCollaborateur = this.rowsCollaborateurConformite
           
            if (this.selectedConformite === 'Conforme') {
                this.rowsCollaborateur = this.rowsCollaborateur.filter(collaborateur => this.checkColaboratteur(this.rowsDocument,collaborateur.user.id));
            } 
            else if (this.selectedConformite === 'Non conforme') { 
                this.rowsCollaborateur = this.rowsCollaborateur.filter(collaborateur => !this.checkColaboratteur(this.rowsDocument, collaborateur.user.id));
            } 
        },
        checkColaboratteur(data, user_id) {
            let rows = this.visibleDocumentParColab(this.docuDocument,user_id);
              rows = rows.map((document) => {
                  let row = data.filter(
                      (item) =>
                          item.id_document_type === document.typeId && item.user_id === user_id
                  );
                  let progression = 'orange';
                  if (row.length > 0) {

                      let timeProperty = '';
                      let hours = 0
                      let minutes = 0

                      let timePropertyFormation = '';
                      let hoursFormation = 0
                      let minutesFormation = 0

                      if (row[0].nbHrsOfFormationCollaborateur != null && row[0].nbHrsOfFormationCollaborateur != '') {
                          hours = Math.floor(row[0].nbHrsOfFormationCollaborateur / 60);
                          minutes = row[0].nbHrsOfFormationCollaborateur % 60;
                          timeProperty = `(${hours}h ${minutes}m)`
                      }

                      if (row[0].nbHrsOfFormation != null && row[0].nbHrsOfFormation != '') {
                          hoursFormation = Math.floor(row[0].nbHrsOfFormation / 60);
                          minutesFormation = row[0].nbHrsOfFormation % 60;
                          timePropertyFormation = `(${hoursFormation}h ${minutesFormation}m)`;
                      }

                      if (row[0].id_document_type === 9) {
                          if (hours >= 15/* && !this.checkDateIsFinsh(row[0].dateFin)*/) {
                              progression = 'vert';
                          }
                          else if (this.checkDateIsFinsh(row[0].dateFin)) {
                              progression = 'red';

                          }
                          else {
                              progression = 'orange';
                          }
                      } else if (this.checkDateIsFinsh(row[0].dateFin)) {
                          progression = 'red';
                      } else {
                          progression = 'vert';
                      }
                      if (row[0].isEtat != 'HISTORISER') {
                          return {
                              ...document,
                              date_debut: row[0].dateDebut,
                              date_fin: row[0].dateFin,
                              doc_telecharge: row[0].NomDuDocument != 'null' && row[0].NomDuDocument != null && row[0].NomDuDocument != '' && row[0].NomDuDocument ? row[0].NomDuDocument : row[0].Name,
                              time: row[0].TypeDeDocument === 'Attestation de formation DDA' ? timeProperty : '',
                              timeFormation: row[0].TypeDeDocument === 'Attestation de formation DDA' ? timePropertyFormation : '',

                              progression: progression,
                              idDocument: row[0].idDocument,
                              idD: row[0].id,
                              NomDuDocument:
                                  row[0].NomDuDocument != 'null' &&
                                      row[0].NomDuDocument != null &&
                                      row[0].NomDuDocument != '' &&
                                      row[0].NomDuDocument
                                      ? row[0].NomDuDocument
                                      : row[0].Name,
                              user_id: user_id,
                          };

                      }
                      else {
                        return {
                            ...document,
                            time: row[0].TypeDeDocument === 'Attestation de formation DDA' ? timeProperty : '',
                            progression: hours >= 15 && row[0].TypeDeDocument === 'Attestation de formation DDA' ? 'vert' : 'orange',
                            user_id: user_id,
                        };
                      }
                  }
                  return {
                      ...document,
                      progression: progression,
                      user_id: user_id,
                  };
              });
              let valide = false
              rows = rows.filter(
                  (item) =>
                      item.progression === 'vert'
              );
              if (rows.length == this.visibleDocumentParColab(this.docuDocument, user_id).length) {
                  valide = true
              }

              return valide
        },
        visibleDocumentParColab(document, user_id) {
            let documentAffiche =  document.filter(
                  (item) =>
                      item.typeLibelle != 'Attestation ORIAS' && item.typeLibelle != 'Attestation RC PRO'
                      && item.typeLibelle != 'Attestation garantie financière' && item.typeLibelle != 'KBIS'
                      && item.typeLibelle != 'Convention d\'apport d\'affaire'
              );
            let statut_juridique = this.rowsCollaborateurConformite.find((item) =>
                item.user.id === user_id
                ).user.personne_physique.statut_juridique;

              if (statut_juridique == 'Co-courtier') {
                documentAffiche.push(document.find(
                    (item) =>
                        item.typeLibelle == 'Attestation garantie financière' 
                ));
              }
                
              if (statut_juridique == 'Co-courtier' || statut_juridique == 'Mandataire') {
                documentAffiche.push(document.find(
                    (item) =>
                        item.typeLibelle == 'Attestation ORIAS' 
                ));
                documentAffiche.push(document.find(
                      (item) =>
                          item.typeLibelle == 'Attestation RC PRO'
                ));
              
            }
            if (statut_juridique == 'Indicateur') {
                documentAffiche.push(document.find(
                    (item) =>
                        item.typeLibelle == 'Convention d\'apport d\'affaire'
                ));
            
            }

            if (statut_juridique == 'Indicateur' || statut_juridique == 'Co-courtier' || statut_juridique == 'Mandataire') {
                documentAffiche.push(document.find(
                    (item) =>
                        item.typeLibelle == 'KBIS'
                ));

            }

        documentAffiche.sort(function (a, b) {
            var nameA = a.typeLibelle.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
            var nameB = b.typeLibelle.toUpperCase();

            if (nameA < nameB) {
                return -1; // nameA comes before nameB
            }
            if (nameA > nameB) {
                return 1; // nameA comes after nameB
            }
            return 0; // names are equal
        });

        return documentAffiche
        
            
        },
        remplierData(data, user_id) {
            if (user_id != null) {
                this.rows = this.visibleDocumentParColab(this.docuDocument, user_id);
                this.rows = this.rows.map((document) => {
                let row = data.filter(
                    (item) =>
                    item.id_document_type === document.typeId && item.user_id === user_id
                );

                let progression = 'orange';
                if (row.length > 0) {
                
                    let timeProperty = '';
                    let hours = 0
                    let minutes = 0

                    let timePropertyFormation = '';
                    let hoursFormation = 0
                    let minutesFormation = 0
             
                    if (row[0].nbHrsOfFormationCollaborateur != null && row[0].nbHrsOfFormationCollaborateur != '') {
                        hours = Math.floor(row[0].nbHrsOfFormationCollaborateur / 60);
                        minutes = row[0].nbHrsOfFormationCollaborateur % 60;
                        timeProperty = `(${hours}h ${minutes}m)`
                    }
                    
                    if (row[0].nbHrsOfFormation != null && row[0].nbHrsOfFormation != '') {
                        hoursFormation = Math.floor(row[0].nbHrsOfFormation / 60);
                        minutesFormation = row[0].nbHrsOfFormation % 60;
                        timePropertyFormation = `(${hoursFormation}h ${minutesFormation}m)`;
                    }
                    

                    if (row[0].id_document_type === 9) {
                        if (hours >= 15 && !this.checkDateIsFinsh(row[0].dateFin) ){
                            progression = 'vert';
                        }
                        else if (this.checkDateIsFinsh(row[0].dateFin)){
                            progression = 'red';

                        }
                        else {
                            progression = 'orange';
                        }
                    } else if (this.checkDateIsFinsh(row[0].dateFin)) {
                        progression = 'red';
                    } else {
                        progression = 'vert';
                    }
                    if (row[0].isEtat != 'HISTORISER') {
                        return {
                            ...document,
                            date_debut: row[0].dateDebut,
                            date_fin: row[0].dateFin,
                            doc_telecharge: row[0].NomDuDocument != 'null' && row[0].NomDuDocument != null && row[0].NomDuDocument != '' && row[0].NomDuDocument ? row[0].NomDuDocument : row[0].Name,
                            time: row[0].TypeDeDocument === 'Attestation de formation DDA' ? timeProperty : '',
                            timeFormation: row[0].TypeDeDocument === 'Attestation de formation DDA' ? timePropertyFormation : '',

                            progression: progression,
                            idDocument: row[0].idDocument,
                            idD: row[0].id,
                            NomDuDocument:
                            row[0].NomDuDocument != 'null' &&
                            row[0].NomDuDocument != null &&
                            row[0].NomDuDocument != '' &&
                            row[0].NomDuDocument
                            ? row[0].NomDuDocument
                            : row[0].Name,
                            user_id: user_id,
                        };
                        
                    }
                    else {
                         return {
                            ...document,
                            time: row[0].TypeDeDocument === 'Attestation de formation DDA' ? timeProperty : '',
                            progression: hours >=15 && row[0].TypeDeDocument === 'Attestation de formation DDA' ? 'vert' : 'orange',                    
                            user_id: user_id,
                        };
                        
                    }
                   
                }

                return {
                    ...document,
                    progression: progression,
                    user_id: user_id,
                };
                });
                this.rowsConst = this.rows;
             } else {
                this.rows = [];
                this.rowsConst = [];
             }
             
            },


            checkDateIsFinsh(date_fin) {
            let check = false
            if (moment().format('YYYY-MM-DD') >= moment(date_fin, 'DD/MM/YYYY').format('YYYY-MM-DD')) {
                check = true
            }
            return check
            
        },
    clearAllFilter() {
      this.selectedConformite = null
    },

    clearAllFilterCollaborateur(){
        this.selectedCollaborateur = null
        this.selectedProgression = null
    }

    }
  }
  </script>
  
  <style lang="scss">
  
  
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  div.custom-table-bottom >div >.vgt-responsive{
      min-height: 0 !important;
  }
  </style>