<template>
    <div>
     
        <div>
                <div class="custom-search">
                    <!-- advance search input -->
                    <b-row class="d-flex justify-content-end ">
                 
                        
                        <b-col  md="3">
                            <b-form-group label="Progression" label-for="progression">
                                <b-form-select id="progression" v-model="selectedProgression" :options="ProgressionOptions" />
                            </b-form-group>
                        </b-col>

                        <b-col md="1" class="mt-1">
                            <b-button  @click = clearDataFilter() v-b-tooltip.hover.v-primary  v-b-tooltip.hover.top="'Tout afficher'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    class="btn-icon mt-1" variant="primary" >
                                <feather-icon icon="RefreshCwIcon" />
                            </b-button>
                        </b-col>

                    </b-row>
                </div>
            <b-card>
                <b-overlay :show="showLoading" no-wrap />

                <!-- table -->
                <vue-good-table :columns="columns" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                }" @on-selected-rows-change="selectionChanged" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }">
                    <template slot="table-row" slot-scope="props">
                        <span class="d-flex justify-content-center" v-if="props.column.field === 'actions'">

                            <span class="cursor-pointer" @click="$emit('add-document', props.row)" v-b-toggle.sidebar-document-conformite>
                                <feather-icon icon="DownloadIcon" size="20" class="mr-50" />
                            </span>

                            <span v-if="props.row.doc_telecharge" class="cursor-pointer"  @click="$emit('delete-docuement', props.row.idDocument)">
                                <feather-icon   icon="Trash2Icon" size="20" class="mr-50" />
                            </span>

                            <span v-else style="opacity: 0.5;">
                                <feather-icon   icon="Trash2Icon" size="20" class="mr-50" />
                            </span>

                            <span v-if="props.row.doc_telecharge"  class="cursor-pointer"  @click="$emit('historise-docuement', props.row.idD)">
                                <feather-icon  icon="RefreshCcwIcon" size="20" class="mr-50" />
                            </span>

                            <span v-else style="opacity: 0.5;">
                                <feather-icon  icon="RefreshCcwIcon" size="20" class="mr-50" />
                            </span>
                        
                        </span>

                        <span class="d-flex justify-content-start" v-if="props.column.field === 'typeLibelle'">
                            {{ props.row.typeLibelle }}
                        </span>
                        
                        <span   v-if="props.column.field === 'date_debut'">
                            {{ props.row.date_debut }}
                        </span>

                        <span  v-if="props.column.field === 'date_fin'">
                            {{ props.row.date_fin }}
                        </span>

                        <span class="cursor-pointer d-flex justify-content-start"  v-if="props.column.field === 'doc_telecharge'"
                          @click="displayDocument(props.row.idDocument, props.row.doc_telecharge, props.row.NomDuDocument)">
                            <span v-if="props.row.doc_telecharge">
                                <feather-icon class="mr-50" icon="EyeIcon"/>
                            </span>
                            {{ props.row.doc_telecharge }}
                        </span>

                        <span class="d-flex justify-content-center" v-if="props.column.field === 'progression'">

                            <span v-if="props.row.progression == 'vert'" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Conforme'"  :class="`${props.row.progression}-dote `"></span>
                            <span v-else-if="props.row.progression == 'red'" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Date dépassée'"  :class="`${props.row.progression}-dote `"></span>
                            <span v-else v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Document manquant'"  :class="`${props.row.progression}-dote `"></span>

                        </span>

                        <!-- <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span> -->
                       
                    </template>

                    <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <b-row class="mt-2 align-items-center">
                    <b-col md="6" lg="5" xxl="3">
                        <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Affichage 1 à
                        </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['3', '5', '10']"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> de {{ props.total }} entrées </span>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2 align-items-center">
                <b-col>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                    </b-pagination>
                </b-col>
            </b-row>
          </template>
                    <div slot="emptystate" class="text-center align-middle">
                        <span>Aucune donn&eacute;e disponible dans le tableau</span>
                    </div>
                </vue-good-table>
                   <b-modal id="modal-display-document" ref="modal-display-document"
                        :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
                        cancel-variant="outline-secondary"
                        ok-title="Télécharger"
                        @ok="downloadDocument(document.base64, document.nameToDownload)"
                >
                <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden' }">
                    <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
                    <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
                </form>
                </b-modal>



            </b-card>
        </div>


    </div>
</template>
  
<script>
import {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BPagination,
    BRow,
    VBToggle,
    VBTooltip,
    BModal,
    BImg,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
//   import ParentFicheReclamation from './detail_fiche_reclamation/ParentFicheReclamation.vue'
import moment from 'moment'
export default {
    components: {
        flatPickr,
        VueGoodTable,
        BPagination,
        BFormGroup,
        BFormInput,
        BBadge,
        BForm,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        BRow,
        BCol,
        BButton,
        BCard,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BFormSelectOption,
        BModal,
        BImg,
        ToastificationContent,
        moment
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            recherche: null,
            pageLength: 10,
            currentUser: JSON.parse(localStorage.getItem('userData')),
            options: [
                {
                    value: null,
                    text: 'Choisissez'
                },
                {
                    value: 'Historiser',
                    text: 'Historiser'
                }
            ],
            columns: [
                {
                    label: 'id',
                    field: 'typeId',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                    hidden: true

                },
                {
                    label: 'Liste des documents',
                    field: 'typeLibelle',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                {
                    label: 'Date de début',
                    field: 'date_debut',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                    sortable: true,
                    sortFn(x, y) {
                        if (x == null && y == null) {
                            return 0;
                        } else if (x == null) {
                            return 1; // Mettre la date nulle à la fin
                        } else if (y == null) {
                            return -1; // Mettre la date nulle à la fin
                        }
                        x = moment(x, 'DD/MM/YYYY');
                        y = moment(y, 'DD/MM/YYYY');
                        if (x.isBefore(y)) {
                            return -1;
                        } else if (x.isAfter(y)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
                {
                    label: 'Date de fin',
                    field: 'date_fin',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                    sortable: true,
                    sortFn(x, y) {
                        if (x == null && y == null) {
                            return 0;
                        } else if (x == null) {
                            return 1; // Mettre la date nulle à la fin
                        } else if (y == null) {
                            return -1; // Mettre la date nulle à la fin
                        }
                        x = moment(x, 'DD/MM/YYYY');
                        y = moment(y, 'DD/MM/YYYY');
                        if (x.isBefore(y)) {
                            return -1;
                        } else if (x.isAfter(y)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                 
                },
                {
                    label: 'Document téléchargé',
                    field: 'doc_telecharge',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                },
                {
                    label: 'Progression',
                    field: 'progression',
                    thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
                    tdClass: 'text-center m-0 p-1 align-middle h6',
                }
            ],
            configDate: {
                dateFormat: 'd/m/Y',
                allowInput: true,
                onKeyDown: (selectedDates, dateStr, instance, event) => {
                    if (event.keyCode != 8) {
                        var ele = document.activeElement
                        var val = ele.value

                        if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                            val += '/'
                        }
                        ele.value = val
                        ele.dispatchEvent(new Event('input'))
                    }
                }
            },
             document: {
                base64: null,
                name: null,
                nameToDownload: null,
                extensionDocument: null
            },
            selectedProgression: null,
            ProgressionOptions: [
                 {
                    value: null,
                    text: '-- Choisissez --'
                },
                  {
                    value: 'vert',
                    text: 'Conforme'
                },
                {
                    value: 'orange',
                    text: 'Document manquant'
                },
                {
                    value: 'red',
                    text: 'Date dépassée'
                },
            ],
            showLoading: false,
            rowsConst: [],
            rows: [],
            searchTerm: '',
            showLoading: false,
            selected_action:null,
           
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    props: {
        rowsDocument: {
            type: Array,
            default: [],
        },
        docuDocument: {
            type: Array,
            default: [],
        },
        
    },
    watch: {
         rowsDocument: {
            deep: true,
            immediate: true,
            handler(val) {
                this.remplierData(val)
                this.rowsConst = this.rows
            }
        },
        selectedProgression() {
            this.filterData()

        }
    },
    mounted() {

    },
    methods: {
        filterData() {
            this.rows = this.rowsConst
            if(this.selectedProgression)
                this.rows = this.rows.filter(item => item.progression === this.selectedProgression)

        },
        clearDataFilter() {
            this.selectedProgression = null
        },
         downloadDocument(base64, name) {
            if (base64) {
                const a = document.createElement('a')
                a.href = base64
                a.download = name
                a.click()
                this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
            } else {
                this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
            }
        },
         clearDocumentDisplay() {
            this.document.base64 = null
            this.document.name = null
            this.document.nameToDownload = null
            this.document.extensionDocument = null
        },
        displayDocument(id, name, NomDuDocument) {
            this.showLoading = true
            this.$http
                .get(`/document/generate_base64_for_document/${id}`)
                .then(res => {
                    if (res.data.success) {
                        this.clearDocumentDisplay()
                        if (res.data.data.isDownload) {
                            this.downloadDocument(res.data.data.base64, name)
                        } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                            this.document.base64 = res.data.data.base64
                            this.document.name = NomDuDocument || name
                            this.document.nameToDownload = name
                            this.document.extensionDocument = res.data.data.extensionDocument
                            this.$refs['modal-display-document'].show()
                        } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                            this.document.base64 = res.data.data.base64
                            this.document.name = NomDuDocument || name
                            this.document.nameToDownload = name
                            this.document.extensionDocument = res.data.data.extensionDocument
                            this.$refs['modal-display-document'].show()
                            // const pdfWindow = window.open('')
                            // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
                            // this.downloadDocument(res.data.data.base64, name)
                        }
                        this.showLoading = false

                    } else {
                        this.showLoading = false
                        this.clearDocumentDisplay()
                        this.messageToast(res.data.message, 'Erreur', 'error')
                    }
                })
                .catch(err => {
                    this.showLoading = false
                    this.clearDocumentDisplay()
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                })
        },
        remplierData(data) {
            this.rows = this.docuDocument
            
            this.rows = this.rows.map(document => {
                let row = data.filter(item => item.id_document_type === document.typeId)
                let progression = 'orange'

                if (row.length > 0) {
                    if (this.checkDateIsFinsh(row[0].dateFin)) {
                        progression = 'red'
                    }
                    else {
                        progression = 'vert'
                    }
                    return {
                        ...document,
                        date_debut: row[0].dateDebut, 
                        date_fin: row[0].dateFin,    
                        doc_telecharge: row[0].NomDuDocument != 'null' && row[0].NomDuDocument != null  && row[0].NomDuDocument != '' && row[0].NomDuDocument ? row[0].NomDuDocument : row[0].Name,
                        progression: progression,
                        idDocument:  row[0].idDocument,
                        idD: row[0].id,
                        NomDuDocument: row[0].NomDuDocument != 'null' && row[0].NomDuDocument != null && row[0].NomDuDocument != '' && row[0].NomDuDocument ? row[0].NomDuDocument : row[0].Name,


                    };
                }
                return {
                    ...document,
                    progression: progression,
                   
                };
            });

            
        },
        checkDateIsFinsh(date_fin) {
            let check = false
            if (moment().format('YYYY-MM-DD') >= moment(date_fin, 'DD/MM/YYYY').format('YYYY-MM-DD')) {
                check = true
            }
            return check
            
        },
        selectionChanged(params) {
            this.selectedrows_masse = params;

        },
       
    }
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style></style>
  