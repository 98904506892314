<template>
  <div>
    <b-sidebar id="sidebar-document-conformite" ref="sideBarDocumentConformite" backdrop
               bg-variant="white" no-header right shadow sidebar-class="sidebar-lg"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>
        <validation-observer ref="documentComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Document -->

            <b-overlay :show="showLoading" no-wrap />

            <b-form-group label="Date début*" label-for="date-debut">
              <validation-provider #default="{ errors }" name="date début" rules="required">
                <flat-pickr id="date-debut-coformite" v-model="composeData.dateDebut"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control" placeholder="Date début"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date fin*" label-for="date-fin">
              <validation-provider #default="{ errors }" name="date fin" rules="required">
                <flat-pickr id="date-fin-coformite" v-model="composeData.dateFin"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDateFin" class="form-control" placeholder="Date fin"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group
              v-if="hasFormation"
              label="Objet de la formation"
              label-for="objet"
            >
                <b-form-input
                  id="objet"
                  trim
                  placeholder="Objet de la formation"
                  v-model="composeData.objet"
                />
            </b-form-group> -->

            <b-form-group
              v-if="hasFormation"
              label="Nombre de minutes*"
              label-for="nbr_heure"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Nombre de minutes"
                  rules="required"
                >
                <b-form-input
                  id="nbr_heure"
                  type="number"
                  placeholder="Nombre de minutes"
                  v-model="composeData.nb_heures"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-b-tooltip.hover="'Parcourir le fichier'" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'"
                              class="btn-icon" @click="openToSelectFile"
                    >
                      <b-form-file id="filecoformite" v-model="composeData.file" class="d-none" type="file"/>
                      <feather-icon icon="UploadCloudIcon"/>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input :state="errors.length > 0 ? false : null"
                                :value="composeData.file ? composeData.file.name : ''"
                                disabled
                                placeholder="Choisissez un document"
                  />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                        @click="recordDocumentCourtierCompliance"
              > Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar,
  BOverlay,
  BButton,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BSidebar,
  VBToggle,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, requiredIf } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/named
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BOverlay,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormFile,
    BAvatar,

    flatPickr,
    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
    props: {
        dataConfirmete: {
            type: Object,
            default: {},
        },
    },
  data() {
      return {
        currentUser: JSON.parse(localStorage.getItem('userData')),
        compteurFormation: 0,
        configDate: {
              dateFormat: 'd/m/Y',
              allowInput: true,
              onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                  const ele = document.activeElement
                  let val = ele.value

                  if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/'
                  }
                  ele.value = val
                  ele.dispatchEvent(new Event('input'))
              }
                },
            disable :[
                    function(date){
                      const today = new Date();
                      return date > today;
                    }
            ]       
          },
          configDateFin: {
                dateFormat: 'd/m/Y',
                altFormat: 'd/m/Y',
                altInput: true,
                disable: [
                    function (date) {
                      // console.log(date)
                        let localDay = new Date();
                        // let firstDayCurrentMonth = new Date(localDay.getFullYear(), localDay.getMonth(), localDay.get());
                        return date <= localDay;
                    }
                ],
                allowInput: true,
                onKeyDown: (selectedDates, dateStr, instance, event) => {
                    if (event.keyCode !== 8) {
                        var ele = event.target || event.srcElement; // Use event.target instead of document.activeElement
                        var val = ele.value;
                        if ((val.length === 2 || val.length === 5) && ele.getAttribute('id')) {
                            val += "/";
                        }
                        ele.value = val;
                        instance.setDate(ele.value, false); // Use instance.setDate to update the selected date
                    }
                }
            },
        required,
        requiredIf,
        isUserObligatoire: false,
        showLoading: false,
        isSendEmail: false,
        typeSelected: null,
        natureSelected: null,
        typeList: [],
        natureList: [],
        composeData: {
            // document courtier
            idDocumentConformite: null,
            dateDebut: null,
            dateFin: null,
            collaborateur: null,

            // document
            id: null,
            nom: null,
            description: '',
            idType: null,
            file: null,
            courtier_id: null,
            objet:null,
            nb_heures:null
        },
        hasFormation:false,
        }
  },
  watch: {
    'composeData.idType':{
      handler(val) {
        if (val == 9) {
          this.hasFormation = true
        }
        else
        {
          this.hasFormation = false
        }
      }
      },
    dataConfirmete: {
        deep: true,
        immediate: true,
        handler(val) {
            this.changeisUserObligatoire(val.isCollaborateurAssujetti)
            this.composeData.idDocumentConformite = val.id
            this.composeData.isSendEmail = !!val.isSendEmail
            this.composeData.idType = val.typeId
            this.isUserObligatoire = !!val.isCollaborateurAssujetti
            this.composeData.courtier_id = this.currentUser.courtier_user[0].courtier_id
            this.composeData.collaborateur = val.user_id                        
        }
    },

  },
  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarDocumentConformite.hide()
    },
    openToSelectFile: () => {
      document.getElementById('filecoformite').click()
    },
    changeisUserObligatoire(val) {
      this.isUserObligatoire = !!val
    },
    clearData() {
      this.composeData.dateDebut = null
      this.composeData.dateFin = null
      this.composeData.idDocumentConformite = null
      this.composeData.isSendEmail = false
      this.composeData.collaborateur = null
      this.composeData.id = null
      this.composeData.nom = null
      this.composeData.description = ''
      this.composeData.idType = null
      this.composeData.file = null
      this.isUserObligatoire = false
      this.typeSelected = null
      this.composeData.objet = null
      this.composeData.nb_heures = null
      this.$refs.documentComposeRules.reset()
      },
      historiserDocumentConformite(documentCourtierId, idDocument, collaborateurId) {
          this.$swal({
              title: "Un document est déjà présent, souhaitez-vous l'archiver ?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
            })
              .then(confirmed => {
                if (confirmed.value) {
                  this.$http
                    .put(`/document/historiserDocumentConformite/${documentCourtierId}`)
                    .then(res => {
                      if (res.data.success) {
                        this.messageToast(res.data.message, 'Succès', 'success');
                      } else {
                        this.messageToast(res.data.message, 'Erreur', "danger");
                      }
                      // if (collaborateurId != null && collaborateurId != '') {
                      //   this.$emit('fetch-colaborateur');
                      // }
                      this.$emit('fetch-document');
                    })
                    .catch(error => {
                      console.log(error);
                      this.messageToast(error.response.data, 'Erreur', "danger");
                    });
                } else {
                  this.deleteDocument(idDocument, collaborateurId);
                }
              });
        },
         deleteDocument(id, collaborateurId) {
          this.$http
            .delete(`/document/delete_document_courtier/${id}`)
            .then(res => {
              if (res.data.success) {
                this.messageToast(res.data.message, 'Succès', 'success')
              } else {
                this.messageToast(res.data.message, 'Erreur', "danger")
              }
              // if (collaborateurId != null && collaborateurId != '') {
              //     this.$emit('fetch-colaborateur')
              // }
              this.$emit('fetch-document')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', "danger")
            })

    },
    // record Data document courtier
    recordDocumentCourtierCompliance() {
      this.$refs.documentComposeRules.validate()
          .then(success => {
            if (success) {
              // Set formData
              this.showLoading = true
              const formData = new FormData()
              // Append the rest of your form data
              formData.append('id', this.composeData.id)
              formData.append('nom', this.composeData.file.name.split(".").slice(0, -1).join("."))
              formData.append('description', this.composeData.description)
              formData.append('idType', this.composeData.idType)
              formData.append('file', this.composeData.file)

              formData.append('courtier_id', this.composeData.courtier_id)
              formData.append('id_document_conformite', this.composeData.idDocumentConformite)
              formData.append('isSendEmail', this.composeData.isSendEmail)
              formData.append('date_debut', this.composeData.dateDebut)
              formData.append('date_fin', this.composeData.dateFin ? this.composeData.dateFin : '')
              formData.append('hasFormation', this.hasFormation)
              formData.append('objet', this.composeData.objet ? this.composeData.objet : '')
              formData.append('nb_heures', this.composeData.nb_heures ? this.composeData.nb_heures : '' )

              const collaborateurId = this.composeData.collaborateur
              formData.append('user_id', collaborateurId)
              // send request
              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              this.$http
                  .post('/document/createNewDocumentCourtierCollaborateur', formData, config)
                  .then(res => {
                      if (res.data.success) {
                       
                        if (this.dataConfirmete.idD) {
                            this.historiserDocumentConformite(this.dataConfirmete.idD,this.dataConfirmete.idDocument, collaborateurId)
                          }
                        else {
                            //  if (collaborateurId != null && collaborateurId != '') {
                            //     this.$emit('fetch-colaborateur')
                            // }
                            this.$emit('fetch-document')
                          }

                        this.clearData()
                        this.hideSideBare()
                    } else {
                      this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', "danger")
                    }
                    this.showLoading = false
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], "Erreur", "danger");
                    console.error(err)
                  })
            }
          })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
